import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createRouter, createWebHistory } from 'vue-router';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';

import App from './App.vue';
import Home from './Home.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faDownload,
  faFilePdf,
  faArrowLeft,
  faLeftLong,
  faRightLong,
  faMinus,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAnglesDown,
  faAngleUp,
  faPrint,
  faArrowUpRightFromSquare,
  faGavel,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

import * as Sentry from '@sentry/vue';

/* add icons to the library */
library.add(
  faDownload,
  faFilePdf,
  faArrowLeft,
  faLeftLong,
  faRightLong,
  faMinus,
  faPlus,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faAnglesDown,
  faAngleUp,
  faPrint,
  faArrowUpRightFromSquare,
  faGavel,
  faBars
);

globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = true;

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/ocr',
    name: 'Ocr',
    component: () => import('./Ocr.vue'),
  },
  {
    path: '/sentenza/:id',
    name: 'Sentenza',
    component: () => import('./Sentenza.vue'),
    props: true,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('./info/About.vue'),
  },
  {
    path: '/methodology',
    name: 'methodology',
    component: () => import('./info/Methodology.vue'),
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('./info/Project.vue'),
  },
  {
    path: '/foreword',
    name: 'foreword',
    component: () => import('./info/Foreword.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('./info/Privacy-Policy.vue'),
  },
  {
    path: '/legal-notes',
    name: 'legal-notes',
    component: () => import('./info/Legal-notes.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.APP_NAME} - ${to.name}`;
  next();
});

window.localStorage.clear();

const app = createApp(App);
Sentry.init({
  app,
  dsn: 'https://48e517e11b214539aa81b012613c9669@o1241947.ingest.sentry.io/4505322581393408',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const pinia = createPinia().use(piniaPluginPersistedState);
app.component('font-awesome-icon', FontAwesomeIcon).use(router).use(pinia).mount('#app');
