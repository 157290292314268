import { sortBy } from 'underscore';

const sortRelated = (sentenza) => {
  const values = sentenza.attributes.correlati.data.map((i) => {
    return { id: i.id, ...i.attributes };
  });
  values.push({
    id: sentenza.id,
    data_causa: sentenza.attributes.data_causa,
    numero_causa: sentenza.attributes.numero_causa,
    document_type: sentenza.attributes.document_type,
    organo_giudicante: sentenza.attributes.organo_giudicante,
  });

  return sortBy(values, function (i) {
    return i.data_causa;
  });
};

const mapEnactingAuthority = (authority) => {
  let val;
  switch (authority) {
    case 'EASA':
    case 'ACER':
    case 'CPVO':
    case 'ECHA':
    case 'ERA':
      val = `${authority} Board of Appeal`;
      break;
    case 'ESAs':
      val = `${authority} Joint Board of Appeal`;
      break;
    case 'SRB':
      val = `${authority} Appeal Panel`;
      break;
    default:
      val = authority;
      break;
  }
  return val;
};

module.exports = { sortRelated, mapEnactingAuthority };
