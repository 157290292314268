<template>
  <div class="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none" id="ErrorMessageModale">
    <div class="relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
      <div class="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
        <div @click="errorStore.msg = ''" class="w-[10px] h-[10px] leading-[10px] z-10 hover:no-underline hover:cursor-pointer hover:opacity-75 absolute top-[10px] right-[10px]">&times;</div>
        <div class="relative px-4 pb-4 pt-10 text-red-900 text-center">{{ value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ErrorMessageModal',
    props: {
      value: {
        type: String,
        required: true,
      },
    },
  };
</script>
<script setup>
  import { useErrorStore } from '../stores/error';
  const errorStore = useErrorStore();
</script>
