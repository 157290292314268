<template>
  <div class="container mx-auto my-5">
    <div class="grid grid-cols-2 items-center gap-3">
      <div class="text-right">Powered by 3W</div>
      <div>
        <a title="3W srl - Web Attitude" href="https://www.3-w.it/" target="_blank">
          <img style="vertical-align: middle" title="3W srl - Web Attitude" src="../../public/logo3w-round.png" alt="3W srl - Web Attitude" width="50" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PoweredBy',
  };
</script>
