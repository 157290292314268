<template>
  <div class="container mx-auto">
    <form class="w-100 max-w-5xl mx-auto bg-grigio rounded p-6 lg:p-8 text-lg" @submit.prevent="searchSentenza('Searching ...', false)" @keypress="clearMsg()">
      <div class="refine-filter-container">
        <div class="grid grid-cols-1 md:grid-cols-2 justify-between gap-x-4">
          <!-- Enacting authority -->
          <div>
            <div class="text-xl font-bold">
              Enacting authority
              <sup>*</sup>
            </div>
            <div class="mt-6">
              <MultiCheckbox v-model:value="searchStore.organo_giudicante" :options="organoGiudicante" />
              <div class="mt-3">
                <button type="button" class="inline-block small" @click.prevent="checkEnactingAuthorities(true)"><span>select all</span></button>
                &nbsp;
                <button type="button" class="inline-block small" @click.prevent="checkEnactingAuthorities(false)"><span>deselect all</span></button>
              </div>
              <div class="mt-3 text-[10px]">* at least 1 Enacting authority must be selected</div>
            </div>
          </div>

          <!-- Document type -->
          <div>
            <div class="text-xl font-bold">Document type</div>
            <div class="grid grid-cols-1 justify-between gap-x-4">
              <div>
                <MultiCheckbox v-model:value="searchStore.document_type" :options="documentType" />
              </div>
              <div>
                <select class="mt-6" v-model="searchStore.rapporteur">
                  <option value="" selected>Any Rapporteur</option>
                  <option v-for="option in rapporteur" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div>
                <select class="mt-6" v-model="searchStore.outcome">
                  <option value="" selected>Any Outcome</option>
                  <option v-for="option in outcome" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- N° CAUSA etc -->
          <div class="mt-10">
            <div class="text-xl font-bold">Other properties</div>
            <div class="mt-6">
              <div class="flex flex-wrap items-center content-center">
                <div><label class="inline-block text-base" for="causa">Case number</label></div>
                <div class="grow"><input type="text" class="pl-2" name="causa" id="causa" v-model="searchStore.numero_causa" /></div>
              </div>
              <div class="flex flex-wrap items-center content-center">
                <div><label class="inline-block text-base" for="nome_parti">Parties</label></div>
                <div class="grow"><input type="text" class="pl-2" name="nome_parti" id="nome_parti" v-model="searchStore.nome_parti" /></div>
              </div>
            </div>
          </div>

          <!-- SEARCH BY DATE -->
          <div class="mt-10">
            <div class="text-xl font-bold">Search by date</div>
            <div class="lg:flex justify-between gap-x-2 mt-6">
              <div>
                <div class="flex items-center mr-4 mb-2">
                  <input
                    type="checkbox"
                    id="search-date-type-single"
                    @change="changeDataTipo($event)"
                    value="single"
                    :checked="searchStore.tipo_data_ricerca === 'single'"
                    class="opacity-0 absolute h-5 w-5"
                  />
                  <SquareCheckbox />
                  <label for="search-date-type-single"><span class="text-base whitespace-nowrap">Search for fixed date</span></label>
                </div>
                <Datepicker
                  :enable-time-picker="false"
                  :disabled="!searchStore.tipo_data_ricerca"
                  hide-offset-dates
                  locale="it"
                  format="dd/MM/yyyy"
                  placeholder="gg/mm/aaaa"
                  auto-apply
                  v-model="searchStore.data_causa"
                  :max-date="new Date()"
                  @update:model-value="setDateValue"
                ></Datepicker>
              </div>
              <div class="flex flex-wrap justify-between mt-3 lg:mt-0">
                <div class="grow w-full">
                  <div class="flex items-center mr-4 mb-2">
                    <input
                      type="checkbox"
                      id="search-date-type-multiple"
                      @change="changeDataTipo($event)"
                      value="multiple"
                      :checked="searchStore.tipo_data_ricerca == 'multiple'"
                      class="opacity-0 absolute h-6 w-6"
                    />
                    <SquareCheckbox />
                    <label for="search-date-type-multiple"><span class="text-base">Search by date range</span></label>
                  </div>
                </div>
                <div class="w-full">
                  <Datepicker
                    v-model="searchStore.data_causa_end"
                    :enable-time-picker="false"
                    :disabled="searchStore.tipo_data_ricerca !== 'multiple'"
                    hide-offset-dates
                    locale="it"
                    format="dd/MM/yyyy"
                    placeholder="gg/mm/aaaa"
                    auto-apply
                    :start-date="new Date()"
                    :max-date="new Date()"
                    @update:model-value="setDateValue"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- TEXT SEARCH -->
          <div class="mt-10">
            <div class="text-xl font-bold">Text</div>
            <div class="mt-6"><input type="text" name="search" id="search" v-model="searchStore.query" autocomplete="off" placeholder="Enter the search text here" /></div>
            <div class="mt-2">
              <a href="#" @click.prevent="showAdvQuery = !showAdvQuery" class="text-xs block relative underline hover:decoration-transparent hover:cursor-pointer" ref="showAdvQueryRef">
                To improve your search, click here
                <div class="absolute bg-white border rounded p-4 bottom-full z-10 left-0 text-xs" v-show="showAdvQuery">
                  <strong>Boolean operators</strong>
                  <br />
                  In the free search, the following Boolean operators can be used:
                  <ul class="pl-4 list-disc">
                    <li>
                      <code style="font-family: monospace">AND</code>
                      → words separated by
                      <code style="font-family: monospace">AND</code>
                      must be present;
                    </li>
                    <li>
                      <code style="font-family: monospace">OR</code>
                      → at least one of the words separated by
                      <code style="font-family: monospace">OR</code>
                      must be present;
                    </li>
                    <li>
                      <code style="font-family: monospace">NOT</code>
                      → the word preceded by
                      <code style="font-family: monospace">NOT</code>
                      must not be present;
                    </li>
                    <li>
                      <code style="font-family: monospace">*</code>
                      (asterisk) → search for sentences containing words whose prefix is equal to the term preceding
                      <code style="font-family: monospace">*</code>
                      , ex:
                      <br />
                      <code style="font-family: monospace">decret*</code>
                      search for decree, decrees, decree, etc;
                    </li>
                    <li>
                      <code style="font-family: monospace">" "</code>
                      (quotes) → search for the exact phrase between the quotes.
                    </li>
                  </ul>
                  <br />
                  <br />
                  The operators can be combined, eg:
                  <br />
                  <code style="font-family: monospace">"state aid" AND "service contract"</code>
                  <br />
                  and you can use parentheses to separate them, eg:
                  <br />
                  <code style="font-family: monospace">"injunction" AND (opposition OR appeal)</code>
                </div>
              </a>
            </div>
          </div>
          <div class="mt-10">
            <div class="text-xl font-bold">&nbsp;</div>
            <div class="flex flex-wrap justify-between mt-6">
              <div>
                <div class="flex items-center mr-4 mb-2 hover:cursor-pointer">
                  <input type="radio" v-model="searchStore.searchMode" name="searchMode" id="searchMode-anyWords" value="anyWords" class="opacity-0 absolute h-5 w-5 hover:cursor-pointer" />
                  <RoundedCheckbox />
                  <label class="inline-block" for="searchMode-anyWords"><span class="text-base">Free search</span></label>
                </div>
              </div>
              <div>
                <div class="flex items-center mr-4 mb-2 hover:cursor-pointer">
                  <input type="radio" v-model="searchStore.searchMode" name="searchMode" id="searchMode-allWords" value="allWords" class="opacity-0 absolute h-5 w-5 hover:cursor-pointer" />
                  <RoundedCheckbox />
                  <label class="inline-block" for="searchMode-allWords"><span class="text-base">All words</span></label>
                </div>
              </div>
              <div>
                <div class="flex items-center mr-4 mb-2 hover:cursor-pointer">
                  <input type="radio" v-model="searchStore.searchMode" name="searchMode" id="searchMode-phrase" value="phrase" class="opacity-0 absolute h-5 w-5 hover:cursor-pointer" />
                  <RoundedCheckbox />
                  <label class="inline-block" for="searchMode-phrase"><span class="text-base">Exact phrase</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-20">
          <div class="min-[489px]:inline-block mb-5 min-[489px]:mr-5 md:mr-10">
            <button type="reset" class="inline-block" @click.prevent="clear()"><span>RESET</span></button>
          </div>
          <div class="min-[489px]:inline-block mb-5">
            <button type="submit" class="inline-block"><span>SEARCH</span></button>
          </div>
        </div>
      </div>
    </form>

    <div v-if="searchStore.total >= 0" class="mt-12 uppercase text-primary-color text-md text-center font-bold grow">Results found: {{ searchStore.total }}</div>

    <div class="search-results">
      <div :class="`mt-12 py-10 bg-grigio slide-in-fwd-center`" v-if="searchStore.total >= 0">
        <div class="uppercase text-primary-color text-2xl text-center font-bold mb-6" v-if="msg">{{ msg }}</div>
        <ul id="searchResultsUl" class="grid grid-cols-1 gap-y-8 md:gap-y-0 md:gap-x-8 max-w-5xl mx-auto">
          <li v-for="(sentenza, idx) in searchStore.results" :key="sentenza" class="search-result bouble relative rounded mb-8 bg-white border border-grigio-medio leading-7 py-8">
            <router-link :to="`/sentenza/${sentenza.id}`" class="hover:cursor-pointer hover:underline px-5 block">
              <strong>
                <span class="text-lg">{{ mapEnactingAuthority(sentenza.attributes.organo_giudicante) }}</span>
                <br />
                <span class="text-xl">
                  <i>{{ sentenza.attributes.document_type }} of {{ formatDate(sentenza.attributes.data_causa) }} in case</i>
                  {{ sentenza.attributes.numero_causa }}
                </span>
              </strong>
              <br />
              {{ sentenza.attributes.nome_parti }}
              <br />
            </router-link>
            <div class="text-xs mt-5 px-5 text-grigio-scuro" v-if="sentenza.attributes.correlati.data.length > 0">
              <div class="uppercase text-black">
                <strong>Further {{ plural('documents', sentenza.attributes.correlati.data) }} of the case:</strong>
                <span class="links" data-tooltip="The following acts may not be relevant for your research. They are listed here for enabling a general overview of the case">?</span>
              </div>
              <ul class="mt-3">
                <li :class="`mt-2 pl-${(idx + 1) * 2}`" v-for="(ref, idx) in sortRelated(sentenza).filter((i) => i.id !== sentenza.id)">
                  <router-link :title="ref.document_type" :to="`/sentenza/${ref.id}`" class="hover:underline">
                    - {{ ref.document_type }} of {{ mapEnactingAuthority(ref.organo_giudicante) }} of {{ formatDate(ref.data_causa) }} in case {{ ref.numero_causa }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="flex flex-wrap mt-6 max-w-5xl mx-auto">
          <div class="uppercase text-primary-color text-md text-center font-bold grow">Results found: {{ searchStore.total }}</div>
          <div class="pages text-right" v-if="searchStore.pageCount > 1">
            <div class="ml-2 inline-block" v-if="searchStore.total > pageSize">
              <button type="button" class="small monospace" @click="inc" v-show="searchStore.currentPage < searchStore.pageCount">
                <span>
                  Load more
                  <font-awesome-icon :icon="['fas', 'angles-down']" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ErrorMessageModal v-if="errorStore.msg != ''" v-model:value="errorStore.msg" />
</template>

<script setup>
  import RoundedCheckbox from './components/RoundedCheckbox';
  import SquareCheckbox from './components/SquareCheckbox';
  import MultiCheckbox from './components/MultiCheckbox';

  import { sortRelated, mapEnactingAuthority } from './api/utils';
  import { useSearchStore } from './stores/search';
  import { useErrorStore } from './stores/error';

  import { ref, onMounted } from 'vue';
  import qs from 'qs';
  import axios from 'axios';
  import pluralize from 'pluralize';
  import dayjs from 'dayjs';

  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  import ErrorMessageModal from './components/ErrorMessageModal.vue';

  const pageSize = ref(10);
  const searchStore = useSearchStore();
  const errorStore = useErrorStore();

  const msg = ref('');
  const organoGiudicante = ref([]);
  const documentType = ref([]);
  const rapporteur = ref([]);
  const outcome = ref([]);
  const showAdvQuery = ref(false);
  const showAdvQueryRef = ref(null);
  const authoritiesCount = ref(0);
  const docTypeCount = ref(0);
  const arr = ['EU General Court', 'European Court of Justice'];

  window.addEventListener('click', (event) => {
    if (showAdvQueryRef.value !== null && !showAdvQueryRef.value.contains(event.target)) {
      showAdvQuery.value = false;
    }
  });

  document.onkeyup = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
      errorStore.msg = '';
    }
  };

  const formatDatepicker = (date) => {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return [year, month, day].join('-');
  };

  const checkEnactingAuthorities = (checked) => {
    if (checked) {
      searchStore.organo_giudicante = organoGiudicante.value.map((i) => {
        return i.name;
      });
    } else {
      searchStore.organo_giudicante = [];
    }
    arr.forEach((i) => {
      searchStore[i] = false;
    });
  };

  const clear = () => {
    searchStore.reset();
    searchStore.organo_giudicante = organoGiudicante.value.map((i) => {
      return i.name;
    });
    searchStore.document_type = documentType.value.map((i) => {
      return i.name;
    });
  };

  onMounted(async () => {
    const requests = ['organo_giudicante', 'document_type', 'rapporteur', 'outcome'].map((r) => axios.get(`${process.env.API_URL}/enum/${r}`));
    const responses = await Promise.all(requests);

    organoGiudicante.value = responses[0].data.map((i) => {
      return { id: i, name: i };
    });
    authoritiesCount.value = organoGiudicante.value.length;

    documentType.value = responses[1].data.map((i) => {
      return { id: i, name: i };
    });
    docTypeCount.value = documentType.value.length;

    rapporteur.value = responses[2].data;
    outcome.value = responses[3].data;

    if (searchStore.organo_giudicante.length === 0) {
      searchStore.organo_giudicante = organoGiudicante.value.map((i) => {
        return i.name;
      });
    }
    if (searchStore.document_type.length === 0) {
      searchStore.document_type = documentType.value.map((i) => {
        return i.name;
      });
    }
  });

  const changeDataTipo = (e) => {
    if (e.target.checked) {
      searchStore.tipo_data_ricerca = e.target.value;
      switch (e.target.id) {
        case 'search-date-type-single':
          searchStore.data_causa_end = null;
          break;
      }
    } else {
      searchStore.tipo_data_ricerca = '';
      searchStore.data_causa = null;
      searchStore.data_causa_end = null;
    }
  };

  const setDateValue = (e) => {
    if (searchStore.data_causa && searchStore.data_causa_end && searchStore.data_causa > searchStore.data_causa_end) {
      errorStore.msg = `Wrong date range: ${formatDatepicker(searchStore.data_causa)} to ${formatDatepicker(searchStore.data_causa_end)}`;
      searchStore.data_causa = null;
      searchStore.data_causa_end = null;
    }
  };

  const clearMsg = async () => {
    msg.value = '';
  };

  const createOp = (term) => {
    return term.includes('*') ? { testo: { $containsi: term.replace(/\*/, '') } } : { testo: { $containsi: ` ${term} ` } };
  };

  const transformAST = (obj) => {
    const result = _transformAST(obj);
    if (!result) {
      return createOp(obj.left.term);
    }
    return result;
  };

  const _transformAST = (obj) => {
    if (!obj.left || !obj.right || !obj.operator) return null;

    let operator = '$' + (obj.operator.includes('implicit') ? 'or' : obj.operator.toLowerCase());

    let leftTerm = obj.left?.term ? createOp(obj.left.term) : _transformAST(obj.left);
    let rightTerm = obj.right?.term ? createOp(obj.right.term) : _transformAST(obj.right);

    return { [operator]: [leftTerm, rightTerm] };
  };

  const searchSentenza = async (txt, isPageLoading) => {
    errorStore.msg = '';

    if (!isPageLoading) {
      searchStore.currentPage = 1;
      searchStore.results.splice(0);
    }

    const values = [];

    const selectedArr = arr.filter((i) => searchStore[i]);
    if (searchStore.organo_giudicante.length === 0) {
      errorStore.msg =
        selectedArr.length > 0
          ? `Select at least one Enacting authority among the values: ${organoGiudicante.value.map((i) => i.name).filter((i) => !arr.includes(i))}`
          : 'Select at least one Enacting authority';
      return;
    }

    if (searchStore.query.length > 0) {
      values.push(transformAST(searchStore.ast));
    }
    if (searchStore.numero_causa.length > 0) {
      values.push({
        numero_causa: { $containsi: searchStore.numero_causa },
      });
    }
    if (searchStore.organo_giudicante.length > 0 && searchStore.organo_giudicante.length !== authoritiesCount.value) {
      let mappedOrganoGiudicante = searchStore.organo_giudicante.map((i) => {
        return {
          organo_giudicante: { $eq: i },
        };
      });

      const selectedArr = arr.filter((i) => searchStore[i]);
      if (selectedArr.length > 0) {
        mappedOrganoGiudicante = [];

        const others = searchStore.organo_giudicante.filter((i) => !arr.includes(i));

        selectedArr.forEach((j) => {
          const parentBoa = others.map((i) => ({ $and: [{ boa: { $eq: i } }, { organo_giudicante: { $eq: j } }] }));
          mappedOrganoGiudicante.push(...parentBoa);
        });
      }

      values.push({ $or: mappedOrganoGiudicante });
    }
    if (searchStore.document_type.length > 0 && searchStore.document_type.length !== docTypeCount.value) {
      let docTypeValues = searchStore.document_type.map((i) => {
        return {
          document_type: { $eq: i },
        };
      });
      values.push({
        $or: docTypeValues,
      });
    }
    if (searchStore.rapporteur.length > 0) {
      values.push({
        rapporteur: { $eq: searchStore.rapporteur },
      });
    }
    if (searchStore.outcome.length > 0) {
      values.push({
        outcome: { $eq: searchStore.outcome },
      });
    }
    if (searchStore.nome_parti.length > 0) {
      values.push({
        nome_parti: { $containsi: searchStore.nome_parti },
      });
    }
    if (searchStore.tipo_data_ricerca === 'single') {
      const data_causa = formatDatepicker(searchStore.data_causa);
      if (data_causa.length > 0) {
        values.push({
          data_causa: {
            $eq: data_causa,
          },
        });
      }
    }
    if (searchStore.tipo_data_ricerca === 'multiple') {
      const start_data_causa = formatDatepicker(searchStore.data_causa);
      const end_data_causa = formatDatepicker(searchStore.data_causa_end);

      values.push({
        $and: [
          {
            data_causa: {
              $gte: start_data_causa,
            },
          },
          {
            data_causa: {
              $lte: end_data_causa,
            },
          },
        ],
      });
    }

    const filters = {
      filters: values.length === 1 ? values[0] : { $and: values },
    };
    // console.log(JSON.stringify(filters));

    const query = qs.stringify(filters, {
      encodeValuesOnly: true, // prettify URL
    });
    // console.log(query);

    const correlatiParams = {
      correlati: {
        fields: ['numero_causa', 'document_type', 'data_causa', 'nome_parti', 'organo_giudicante'],
      },
    };

    const params = qs.stringify(
      {
        sort: 'data_causa:desc',
        fields: ['numero_causa', 'document_type', 'data_causa', 'nome_parti', 'organo_giudicante', 'identificativo'],
        pagination: { page: searchStore.currentPage, pageSize: pageSize.value },
        populate: correlatiParams,
      },
      {
        encodeValuesOnly: true,
      }
    );

    const endpoint = `${process.env.API_URL}/sentenze?${params}&${query}`;
    const res = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${process.env.TOKEN}`,
      },
    });

    searchStore.results.push(...res.data.data);
    searchStore.pageCount = res.data.meta.pagination.pageCount;
    searchStore.total = res.data.meta.pagination.total;
    // console.log(searchStore.total);

    if (searchStore.currentPage == 1) gotoResults();
  };

  const inc = () => {
    if (searchStore.currentPage < searchStore.pageCount) {
      searchStore.currentPage++;
      searchSentenza('Loading ...', true);
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format(process.env.DATE_FORMAT);
  };

  const plural = (txt, arr) => {
    return pluralize(txt, arr.length);
  };

  const gotoResults = () => {
    let sr = document.querySelector('.search-results');
    if (sr != null) {
      sr.scrollIntoView();
    }
  };
</script>

<style>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  li.search-result.bouble:after {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background: linear-gradient(45deg, white 50%, var(--grigio) 50%);
    top: -1px;
    right: -1px;
    border-left: 1px solid var(--grigio-medio);
    border-bottom: 1px solid var(--grigio-medio);
    border-bottom-left-radius: 4px;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  li.search-result.bouble:hover:after {
    width: 0;
    height: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .dp__theme_light {
    --primary-color: #002841;
    --grigio: #efefef;
    --grigio-scuro: #6f6f6f;
    --grigio-medio: #c9c9c9;
    --text-main: #000;
    --dp-text-color: var(--text-main);
    --dp-hover-color: var(--grigio-medio);
    --dp-hover-text-color: var(--text-main);
    --dp-primary-color: var(--primary-color);
    --dp-border-color: var(--grigio-medio);
    --dp-menu-border-color: var(--grigio-medio);
    --dp-border-color-hover: var(--grigio-medio);
    --dp-disabled-color-text: var(--grigio-medio);
  }

  .dp__input {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--grigio-medio);
    height: 36px;
    color: var(--primary-color);
    width: 100%;
    background-color: #0000;
    font-size: 16px;
  }
  /* .bg-grigio .dp__input {
    background-color: #ffffff;
  } */

  .search-results {
    box-shadow: 0 0 0 100vmax var(--grigio);
    clip-path: inset(0 -100vmax);
  }
  .search-results ul {
    list-style-type: none;
  }
  /* .search-results ul#searchResultsUl > li:hover {
    animation: shadow-drop-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.2s;
  } */
  .links {
    background: var(--primary-color);
    height: 20px;
    width: 20px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 11px;
    display: inline-block;
    vertical-align: text-bottom;
  }
  .links:hover {
    text-decoration: none;
    color: var(--primary-color);
    background: var(--grigio);
  }

  .links-new-tab {
    background: var(--grigio);
    color: var(--primary-color);
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 11px;
  }
  .links-new-tab:hover {
    text-decoration: none;
    color: #fff;
    background: var(--primary-color);
  }

  .slide-in-fwd-center {
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @keyframes slide-in-fwd-center {
    0% {
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes shadow-drop-center {
    0% {
      box-shadow: 0 0 0 0 transparent;
    }
    100% {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    }
  }

  .pdf_viewer_header .zooms {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .pdf_viewer_header div {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }

  .pdf_viewer_header button {
    padding: 10px;
    margin: 0;
  }

  .result-links ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  /* base */
  .search-result {
    backface-visibility: hidden;
    z-index: 1;
  }

  /* moving */
  .search-result-move {
    transition: all 600ms ease-in-out 50ms;
  }

  /* appearing */
  .search-result-enter-active {
    transition: all 400ms ease-out;
  }

  /* disappearing */
  .search-result-leave-active {
    transition: all 200ms ease-in;
    position: absolute;
    z-index: 0;
  }

  /* appear at / disappear to */
  .search-result-enter,
  .search-result-leave-to {
    opacity: 0;
  }
</style>
