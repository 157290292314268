import { defineStore } from 'pinia';

const defaultState = {
  msg: '',
};

export const useErrorStore = defineStore({
  id: 'error',
  state: () => ({
    ...defaultState,
  }),
  persist: {
    storage: localStorage,
  },
});
