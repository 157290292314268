<template>
  <nav class="">
    <div class="container flex flex-wrap items-center justify-end max-w-5xl mx-auto relative">
      <div id="logo-fadeIn" class="absolute top-1/2 left-0 flex gap-2 items-center content-center" style="transform: translateY(-50%)">
        <div><img class="h-16" src="../../public/logo-arco.png" /></div>
      </div>

      <button class="hmenu button-three md:hidden" aria-controls="primary-navigation" :aria-expanded="ariaExpanded" @click="toggleNav()" :data-state="currentState">
        <svg stroke="var(--button-color)" fill="none" class="hamburger" viewBox="-10 -10 120 120" width="50">
          <g id="scalingcontainer">
            <path class="line" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" d="m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 30 v 70"></path>
          </g>
        </svg>
      </button>
      <div :class="`w-full md:block md:w-auto`" id="navbar-default" :aria-expanded="ariaExpanded" :data-route-name="this.$route.name">
        <ul class="flex flex-col p-0 m-0 md:flex-row md:items-end">
          <li>
            <router-link
              to="/"
              :class="`text-primary-color text-xl md:text-sm inline-block ${this.$route.name == 'home' || this.$route.name == 'Sentenza' ? 'font-bold' : ''}`"
              role="link"
              title="Home"
            >
              <span class="inline-block md:mr-3">Home</span>
            </router-link>
            <span class="hidden md:inline">|</span>

          </li>
          <li>
            <router-link to="/foreword" :class="`text-orange-500 font-bold text-xl md:text-sm inline-block md:ml-3 ${this.$route.name == 'foreword' ? 'font-bold' : ''}`" role="link" title="Foreword">
              <span class="inline-block md:mr-3">Foreword</span>
            </router-link>
            <span class="hidden md:inline">|</span>
          </li>
          <li>
            <router-link to="/project" :class="`text-primary-color font-bold text-xl md:text-sm inline-block md:ml-3 ${this.$route.name == 'project' ? 'font-bold' : ''}`" role="link" title="Project">
              <span class="inline-block md:mr-3">Project</span>
            </router-link>
            <span class="hidden md:inline">|</span>

          </li>
          <li>
            <router-link
              to="/methodology"
              :class="`text-primary-color text-xl md:text-sm font-bold inline-block md:ml-3 ${this.$route.name == 'methodology' ? 'font-bold' : ''}`"
              role="link"
              title="Methodology"
            >
              <span class="inline-block md:mr-3">Methodology</span>
              <!-- <span class="hidden md:inline">|</span> -->
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/chi-siamo" :class="`text-primary-color text-xl md:text-sm inline-block md:ml-3 ${this.$route.name == 'about' ? 'font-bold' : ''}`" role="link" title="Chi siamo">
              Chi siamo
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'NavBar',
  };
</script>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useSearchStore } from '../stores/search';
  import { useRouter } from 'vue-router';

  const searchStore = useSearchStore();
  const router = useRouter();

  const navVisible = ref(false);
  const currentState = ref('closed');
  const ariaExpanded = ref(false);
  const toggleNav = () => {
    navVisible.value = !navVisible.value;
    if (currentState.value === 'closed') {
      currentState.value = 'opened';
      ariaExpanded.value = true;
    } else {
      currentState.value = 'closed';
      ariaExpanded.value = false;
    }
  };

  onMounted(async () => {
    window.addEventListener(
      'scroll',
      function () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let varNumber = (scrollTop < 100 ? scrollTop : 100) / 1 / 100;
        document.getElementById('navigation-small').style['backgroundColor'] = 'rgba(255,255,255,' + varNumber + ')';
        document.getElementById('navigation-small').style['boxShadow'] = '-4px 4px 3px rgb(0 0 0 / ' + varNumber * 8 + '%)';
        document.getElementById('logo-fadeIn').style['opacity'] = varNumber;
      },
      false
    );
  });
</script>

<style>
  button.hmenu {
    background: transparent;
    border: 2px solid var(--button-color, #fff);
    border-radius: 10px;
  }

  .button-three {
    --button-color: white;
    overflow: hidden;
  }

  .button-three .hamburger {
    transition: translate 1s, rotate 1s;
  }

  .button-three[aria-expanded='true'] .hamburger {
    translate: 3.5px -3px;
    rotate: 0.125turn;
  }

  .button-three .line {
    transition: 0.5s;
    stroke-dasharray: 60 31 60 300;
  }

  .button-three[aria-expanded='true'] .line {
    stroke-dasharray: 60 105 60 300;
    stroke-dashoffset: -90;
  }

  #logo-fadeIn {
    opacity: 0;
  }

  @media screen and (max-width: 767.98px) {
    #navbar-default {
      position: absolute;
      top: 130%;
      background: var(--grigio);
      width: auto;
      right: 0;
      overflow: hidden;
      transition: 0.5s;
    }
    #navbar-default[aria-expanded='false'] {
      max-height: 0;
    }
    #navbar-default[aria-expanded='true'] {
      max-height: 300px;
    }
    #navbar-default ul {
      padding: 30px;
    }
    #navbar-default ul li a {
      white-space: nowrap;
      display: block;
      text-align: right;
      color: var(--grigio-scuro);
    }
  }
</style>
