<template>
  <div>
    <div class="flex items-center mb-2 hover:cursor-pointer flex-wrap relative" v-if="label !== 'EU General Court' && label !== 'European Court of Justice'">
      <input
        @input="(event) => $emit('update:checked', event.target.checked)"
        type="checkbox"
        :checked="checked"
        :id="fieldId"
        class="opacity-0 absolute h-5 w-5 hover:cursor-pointer autority-checkbox top-[4px]"
      />
      <SquareCheckbox />
      <label :for="fieldId" class="inline-block">
        <span class="text-base">{{ mapEnactingAuthority(label) }}</span>
        <a
          href="https://euipo.europa.eu/eSearchCLW/"
          target="_blank"
          v-if="label.includes('EUIPO')"
          class="links"
          data-tooltip="To extend your research to others BoAs of EUIPO, click here!"
          style="font-size: 10px"
        >
          ?
        </a>
      </label>
    </div>

    <div v-if="label === 'EU General Court' || label === 'European Court of Justice'" style="flex-basis: 100%">
      <div class="text-base">{{ label }}</div>
      <div class="flex items-center mt-2 hover:cursor-pointer flex-wrap relative pl-2">
        <input
          @input="(event) => $emit('update:checked', event.target.checked)"
          type="checkbox"
          :checked="checked"
          :name="fieldId"
          :id="fieldId"
          class="opacity-0 absolute h-5 w-5 hover:cursor-pointer autority-checkbox top-[4px]"
          @change="toggleCheck($event)"
        />
        <SquareCheckbox />
        <label :for="fieldId" class="inline-block leading-3">
          <span class="text-xs">ALL</span>
        </label>
      </div>

      <div class="flex items-start hover:cursor-pointer flex-wrap relative pl-2">
        <div class="pt-2">
          <input
            type="checkbox"
            :name="`${prefix}${fieldId}`"
            v-model="searchStore[fieldId]"
            :id="`${prefix}${fieldId}`"
            class="opacity-0 absolute h-5 w-5 hover:cursor-pointer autority-checkbox top-[8px]"
            @change="toggleCheck($event)"
          />
          <SquareCheckbox />
        </div>
        <label :for="`${prefix}${fieldId}`" class="inline-block hover:cursor-pointer leading-3 pt-2">
          <span class="text-xs">
            Related to the BoA(s)
            <br />
            selected above
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import SquareCheckbox from './SquareCheckbox';
  export default {
    props: {
      label: String,
      fieldId: {
        type: String,
        required: true,
      },
      checked: {
        type: Boolean,
      },
    },
    components: {
      SquareCheckbox,
    },
  };
</script>
<script setup>
  import { mapEnactingAuthority } from '../api/utils';
  import { useSearchStore } from '../stores/search';

  const searchStore = useSearchStore();
  const prefix = 'related_to_';

  const toggleCheck = (event) => {
    if (event.target.name.startsWith(prefix) && event.target.checked) {
      searchStore.organo_giudicante = searchStore.organo_giudicante.filter((i) => i !== event.target.name.replace(prefix, ''));
    }
    if (!event.target.name.startsWith(prefix) && event.target.checked) {
      searchStore[event.target.name] = false;
    }
  };
</script>
