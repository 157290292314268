<template>
  <footer class="mt-32 py-5">
    <div class="bg-grigio py-4">
      <div class="container mx-auto max-w-5xl">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 items-center justify-center content-center">
          <div class="text-center">
            <div class="inline-block border border-grigio-medio bg-white p-1">
              <img class="inline-block" src="../../public/EACEA.png" alt="EACEA" />
            </div>
          </div>
          <div class="text-center"><img class="mx-auto max-w-xs" src="../../public/logo-unife-eng.png" /></div>
          <div class="text-center">
            <a
              href="http://www.giuri.unife.it/it/dipartimento/aree-di-interesse-1/modulo-jean-monnet-eu-specialized-judicial-protection/modulo-jean-monnet-eu-specialized-judicial-protection-EN"
              target="_blank"
            >
              <img class="inline-block" src="../../public/logo_jmm.png" alt="logo jmm" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-color py-4">
      <div class="container mx-auto">
        <div class="text-center text-white">
          <!-- <h1 class="text-white mb-4">
            <span class="text-2xl"><font-awesome-icon icon="fa-solid fa-gavel" /></span>
            <i class="text-xl">&nbsp;{{ appName }}</i>
            <br />
            by
            <a href="https://gam.eurojus.it/" class="underline hover:no-underline" target="_blank">Jacopo Alberti</a>
            is licensed under a
            <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/" class="underline hover:no-underline" target="_blank">
              Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License.
            </a>
          </h1> -->

          <div class="text-center">
            <p data-v-43a2b1="" class="text-xs mx-auto mb-3" style="max-width: 800px"> The Common Database on EU Agencies' Boards of Appeal belongs to Prof. Jacopo Alberti of the University of Ferrara and has been implemented as a scientific product of the Jean Monnet Module on EU Specialized Judicial Protection financed by the European Commission. The documents published therein replicate their peers adopted by the bodies monitored by the present research project and thus have no official legal value. All rights reserved.
            </p>  

            <p class="text-xs">
              &copy; {{ new Date().getFullYear() }} All rights reserved.
              <router-link class="underline hover:no-underline" to="/legal-notes" role="link" title="Note legali">Legal notes</router-link>
            </p>
            <p class="text-xs">
              This site uses cookies to improve your navigation. Consult the
              <router-link class="underline hover:no-underline" to="/privacy-policy" role="link" title="Privacy Policy">Privacy Policy</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'Footer',
  };
</script>
<script setup>
  import { useRouter } from 'vue-router';
  const appName = process.env.APP_NAME;
  const router = useRouter();
</script>
