import { defineStore } from 'pinia';
import parser from 'lucene-query-parser';

const defaultState = {
  numero_causa: '',
  query: '',
  outcome: '',
  nome_parti: '',
  tipo_data_ricerca: '',
  data_causa: null,
  date_causa_end: null,
  rapporteur: '',
  document_type: [],
  searchMode: 'anyWords',
  results: [],
  currentPage: 1,
  pageCount: 0,
  total: -1,
  organo_giudicante: [],
  msgError: '',
  'EU General Court': false,
  'European Court of Justice': false,
};

export const useSearchStore = defineStore({
  id: 'search',
  state: () => ({
    ...defaultState,
  }),
  getters: {
    ast() {
      let ast;
      switch (this.searchMode) {
        case 'phrase':
          const synonyms = [this.query];

          if (this.query.toLowerCase() === 'standard of review') {
            synonyms.push('limit of review', 'intensity of review', 'level of scrutiny');
          }

          ast = parser.parse(synonyms.map((i) => `"${i}"`).join(' OR '));
          break;
        case 'allWords':
          ast = parser.parse(this.query.split(' ').join(' AND '));
          break;
        case 'anyWords':
          ast = parser.parse(this.query);
          break;
      }
      return ast;
    },
    phraseSearch() {
      return this.searchMode === 'phrase' ? true : false;
    },
  },
  actions: {
    reset() {
      Object.assign(this, defaultState);
      this.results = [];
    },
  },
  persist: {
    storage: localStorage,
  },
});
