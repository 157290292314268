<template>
  <!-- <header id="header" :class="`mb-10 md:mb-20 ${this.$route.name != 'Sentenza' ? 'header-bg' : ''}`"> -->
  <header id="header" class="mb-10 md:mb-20 header-bg">
    <div class="container mx-auto relative max-w-5xl px-0">
      <div id="navigation-small" class="fixed z-10 top-0 left-0 mx-auto w-full py-6">
        <div class="container mx-auto"><NavBar /></div>
      </div>

      <div class="grid grid-cols-2 py-28 content-center text-primary-color" style="padding-top: 5.5rem!important">
        <div><a href="https://europeanlitigation.eu/" target="_blank"><img class="logoTop max-w-[230px]" src="../../public/logo-arco.png" alt="" /></a></div>
        <div class="grow">
          <div class="max-w-md mx-auto text-3xl text-black sf-ui-bold">{{ appName }}</div>
          <div class="max-w-md mx-auto text-1xl text-black mt-1.5"><b>edited by Prof. Jacopo Alberti,</b> University of Ferrara</div>
          <div class="max-w-md mx-auto text-1xl text-black mt-1.5"><b>with a Foreword by Judge Marc Jaeger,</b> EU General Court</div>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
  const appName = process.env.APP_NAME;
</script>
<script>
  import NavBar from './NavBar';
  export default {
    name: 'Header',
    components: { NavBar },
  };
</script>
<style scoped>
  header {
    background-color: #fff;
    width: 100%;
    position: relative;
  }
  header.header-bg:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-image: url(../../public/header-bg_2.jpg);
    background-repeat: no-repeat;
    /* background-position: 50% 4%; */
    background-position: 50% -135px;
    background-attachment: fixed;
  }

  @media screen and (max-width: 339.98px) {
    .logoTop {
      max-width: 100%;
    }
  }
</style>
